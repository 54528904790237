import React, {useState} from "react";
import "./Header.css";
import {Link as RouterLink} from "react-router-dom";
import "../../Containers/Main/Main.css";
import BookingDialog from "../../Components/BookingDialog/BookingDialog";
import Burger from "../Burger/Burger";

const Header = (props) => {
  
  
  return (
    <>
      <header className={props.color}>
        <BookingDialog handleClose={props.handleClose} isOpen={props.isOpen} />
        <div className="header">
          <Burger/>
  
          <RouterLink href="#" className="logo" to="/" />
          <nav className="header__menu">
            <RouterLink className="header__menu-link" to="/services">
              Услуги
            </RouterLink>
            <RouterLink className="header__menu-link" to="/prices">
              Цены
            </RouterLink>
            <RouterLink className="header__menu-link" to="/contacts">
              Контакты
            </RouterLink>
            <RouterLink className="header__menu-link" to="/about">
              О нас
            </RouterLink>
          </nav>
            <span className="toBook ms_booking">
              Онлайн запись
            </span>
        </div>
      </header>
    </>
  )
};

export default Header;