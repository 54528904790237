import React from "react";
import "./Prices.css";
import PriceList from "./PriceList/PriceList";


class Prices extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <>
        <div className="prices">
          <div className="container">
            <h1 className="prices__heading"><span className="prices__heading__text">Цены</span></h1>
            <PriceList/>
          </div>
        </div>
      </>
    )
  }
}

export default Prices;