import React from "react";
import "./Gallery.css";

import Pic_1 from "../../../Assets/content/gallery/pic1.jpg";
import Pic_2 from "../../../Assets/content/gallery/pic2.jpg";
import Pic_3 from "../../../Assets/content/gallery/pic3.jpg";
import Pic_6 from "../../../Assets/content/gallery/pic6.jpg";
import Pic_7 from "../../../Assets/content/gallery/pic7.jpg";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination, EffectCoverflow, Autoplay} from "swiper";

import 'swiper/swiper.scss';
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "swiper/components/pagination/pagination.scss";
import VizSensor from "../../../HOC/VizSensor/VizSensor";

const Gallery = (props) => {
  
  SwiperCore.use([Pagination, EffectCoverflow, Autoplay]);
  return (
    <div>
      <div className="title-wrapper title-wrapper--gallery">
        <h1 className="main__title">Наш салон</h1>
        <div className="main__title-info">
          <h3 className="main__subtitle"/>
          <p className="main__title-text">Место Вашей гармонии</p>
        </div>
      
      </div>
      
      
      <div style={props.style} className="swiper-container gallery">
        <div className="swiper-wrapper gallery__wrapper">
          <Swiper
            effect="coverflow"
            loop
            slidesPerView="1"
            autoplay={{
              delay:2000
            }}
            coverflowEffect={{
              rotate: 50
            }}
            pagination={{clickable: true, el: ".swiper-pagination-custom"}}
            breakpoints={{
              600: {
                effect: "coverflow",
                slidesPerView: "2"
              },
              800: {
                slidesPerView: "3"
              }
            }}
          >
            <SwiperSlide>
              <div className="swiper-slide gallery__slide">
                <div className="gallery__img-wrapper">
                  <img src={Pic_1} alt="" className="gallery__img"/>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide gallery__slide">
                <div className="gallery__img-wrapper">
                  <img src={Pic_2} alt="" className="gallery__img"/>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide gallery__slide">
                <div className="gallery__img-wrapper">
                  <img src={Pic_3} alt="" className="gallery__img"/>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide gallery__slide">
                <div className="gallery__img-wrapper">
                  <img src={Pic_6} alt="" className="gallery__img"/>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide gallery__slide">
                <div className="gallery__img-wrapper">
                  <img src={Pic_7} alt="" className="gallery__img"/>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>
        
        </div>
      </div>
      <div className="swiper-pagination-custom"/>
    </div>
  )
};

export default VizSensor(Gallery)