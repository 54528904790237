import React, {useState, useEffect} from "react";
import "./SpecialistsCards.css";

import Olesya from "../../Assets/content/specialists/olesya.jpg";
import Nataliya from "../../Assets/content/specialists/nataliya.jpg";
import Alexander from "../../Assets/content/specialists/alexander.jpg";
import Sergei from "../../Assets/content/specialists/serghei.jpg"
import Maxim from "../../Assets/content/specialists/maxim.jpg"
import SpecialistCard from "./SpecialistCard/SpecialistCard";

import {Swiper, SwiperSlide} from "swiper/react";


import 'swiper/swiper.scss';
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "swiper/components/pagination/pagination.scss";
import {specialists} from "../BookingDialog/BookingDialogDB";


const SpecialistsCards = () => {
  const [specialistsData, setSpecialistsData] = useState([]);
  
  useEffect(() => {
    fetch('https://strapi-cms.sansara-spa.com/specialists')
      .then(r => r.json())
      .then((data) => {setSpecialistsData(data)})
  }, [])
  console.log(specialistsData);
  return (
    <>
      <div className="title-wrapper title-wrapper--specialists">
        <h1 className="main__title">Наши <br/> специалисты</h1>
        <div className="main__title-info">
          <h3 className="main__subtitle">Наша цель - Ваши доверие и благодарность</h3>
          <p className="main__title-text">Наши специалисты ежегодно повышают квалификацию, чтобы суметь помочь каждому отдельному клиенту.</p>
        </div>
      
      </div>
      <div className="card--test__container">
        {
          specialistsData.map(specialist => (
            <SpecialistCard
              name={specialist.name}
              image={`https://strapi-cms.sansara-spa.com/${specialist?.photo?.url}`}
              specialization={specialist.description}
            />
          ))
        }
        
        <SpecialistCard/>

      </div>
      
      <div className="specialists_swiper">
        <Swiper
          loop
          slidesPerView="1"
          spaceBetween={50}
          coverflowEffect={{
            rotate: 50
          }}
          pagination={{clickable: true, el: ".swiper-pagination-custom"}}
          breakpoints={{
            550: {
              slidesPerView: "2"
            }
          }}
        >
          {
              specialistsData.map(specialist => (
                <SwiperSlide>
                  <SpecialistCard
                    name={specialist.name}
                    image={`https://strapi-cms.sansara-spa.com/${specialist?.photo?.url}`}
                    specialization={specialist.description}
                  />
                </SwiperSlide>
              ))
          }
        </Swiper>
      </div>
    </>
  )
};

export default SpecialistsCards;

