import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          
          <div className="footer__head-wrapper">
            <div className="line"/>
            <div className="footer__head">
              <span className="logo logo--footer"/>
              <h1 className="footer__title">Свяжитесь с нами</h1>
            </div>
            <div className="line"/>
          </div>
          
          
          <div className="footer__content">
            <div className="footer__col">
              <a href="tel:+74995012969" className="footer__link">+7 (499) 501 29 69</a>
              <a href="tel:+79257900513" className="footer__link">+7 (925) 790 05 13</a>
              <a href="mailto:sansara-spa@yandex.ru" className="footer__link">sansara-spa@yandex.ru</a>
            
            </div>
            <div className="footer__col media">
              <a href="https://wa.me/79257900513" className="media__link media__link--whatsapp"/>
              <a target="_blank" href="https://www.instagram.com/sansara_spa_/" className="media__link media__link--insta"/>
              <a href="" className="media__link media__link--tg"></a>
            </div>
            <div className="footer__col">
              <a target="_blank" href="https://yandex.ru/maps/org/sansara_spa_kurkino/26564273386/?ll=37.388761%2C55.889783&z=16" className="footer__link">г. Москва, микрорайон «Куркино», ул. Воротынская, дом 18, ТЦ
                «Корабль»,
                цокольный этаж</a>
            
            </div>
          </div>
          {/*<div className="footer__form">
            <h2 className="footer__subtitle">Подпишитесь на наши новости:</h2>
            <form>
              <div className="footer__form-in">
                <div className="footer__input-wrapper">
                  <input type="text" className="footer__input" placeholder="Email"/>
                </div>
                <div className="footer__input-wrapper">
                  <input type="text" className="footer__input" placeholder="Name"/>
                </div>
                <button type="submit" class="footer__btn">Subscribe</button>
                <div className="footer__btn-wrapper">
                  <button className="footer__btn" type="submit">
                    <span>Подписаться</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          */}
          <div className="footer__bottom">
            <p className="footer__p">© 2021 Спа-центр “Sansara-spa”. Все права защищены. </p>
            <a href="#" className="footer__policy">Политика конфиденциальности</a>
          </div>
        </div>
      
      </footer>
    </>
  );
};

export default Footer;