import React from "react";
import "./SubservicePage.css";
import {pages} from "./SubservicePageDB";

const SubservicePage = (props) => {
  const service = pages[props.match.params.service] || {};
  const subservice = service[props.match.params.subservice] || 404;
  
  
  return (
    <>
      <div className="kindsOfService-promo">
        <div className="container">
          <div className="kindsOfService-promo__content">
            <div className="kindsOfService-promo__img">
              <img
                src={subservice.image}
                alt=""/>
            </div>
            <div className="kindsOfService-promo__descr">
              <h1 className="kindsOfService-promo__title">{subservice.title}</h1>
              <p className="kindsOfService-promo__text">{subservice.description}</p>
            </div>
          </div>
        </div>
      
      
      </div>
      <div className="kindsOfService__gallery">
        
        
      
      
      </div>
    </>
  );
};

export default SubservicePage;