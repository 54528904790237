import React, {useState} from "react";
import "./Layout.css";
import Header from "./Header/Header"
import Footer from "./Footer/Footer";


const Layout = (props) => {
  const {isOpen, handleOpen, handleClose} = props
  
  return (
    <>
      <Header isOpen={isOpen} handleOpen={handleOpen} handleClose={handleClose} color={props.color}/>
      <div  className="ms_booking booking__wrapper">
        <span>Онлайн запись</span>
      </div>
      
      <div className="whatsapp-link__wrapper">
        <a target="_blank" href="https://wa.me/79257900513" className="whatsapp-link"/>
      </div>
      
      <div className="page_wrapper">
        {props.children}
      </div>
      <Footer/>
    </>
  )
};

export default Layout;