import React from "react";
import "./ServicePage.css";
import ServiceCard from "../../Components/ServicesCards/ServiceCard/ServiceCard";
import {pages} from "./ServicePageDB";
import PriceRow from "../../Components/PriceRow/PriceRow";

import {Swiper, SwiperSlide} from "swiper/react";

import SwiperCore, {Pagination} from "swiper";
import {Parallax} from "react-parallax";
import ServiceTitle from "../../Components/BookingDialog/Components/ServiceTitle/ServiceTitle";


class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }
  
  state = {
    priceList: []
  };
  
  
  handleScroll() {
    if (this.body.getBoundingClientRect().top > 0 && (this.body.dataset.color !== this.props.color)) {
      
      this.props.setColor(this.body.dataset.color);
      
    }
    
    if (this.coffee && this.coffee.offsetTop < window.scrollY && (this.coffee.dataset.color !== this.props.color)) {
      this.props.setColor(this.coffee.dataset.color);
    }
    
    
  }
  
  componentDidMount() {
    const serviceName = this.props.match.params.service;
    fetch(`https://strapi-cms.sansara-spa.com/price-categories?_title=${serviceName}`).then(r => r.json()).then(response => {
      this.setState({...this.state, priceList: response[0].prices})
    }).catch(() => {});
    window.addEventListener("scroll", this.handleScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  
  render() {
    
    SwiperCore.use([Pagination]);
    
    const service = pages[this.props.match.params?.service] || 404;
    const cards = service?.subserviceCards || [];
    return (
      <>
        <div className="servicePage-promo">
          <div className="servicePage__container container">
            
            <div className="servicePage-promo__content">
              <div className="servicePage-promo__img">
                <img src={service?.promoImage} alt=""/>
              </div>
              <Parallax renderLayer={percentage => (
                <div style={{transform: `translateY(${percentage * 200 - 150}px)`}}
                     className="servicePage-promo__descr">
                  <h1 className="servicePage-promo__title">{service?.promoTitle}</h1>
                  <p className="servicePage-promo__text">{service?.promoText}</p>
                </div>
              )}
              />
              <div className="servicePage-promo__descr servicePage-promo__descr-min">
                <h1 className="servicePage-promo__title">{service?.promoTitle}</h1>
                <p className="servicePage-promo__text">{service?.promoText}</p>
              </div>
            </div>
          </div>
        
        
        </div>
        <ServiceTitle title={service?.serviceTitle}/>
        <div className="container servicePage__container">
          <div className="about-service">
            
            <div className="about-service__text">
              <h2 className="about-service__subtitle">{service?.aboutSubitile}</h2>
              <p className="about-service__descr">{service?.aboutDescription}</p>
            
            </div>
            <div className="about-service__img">
              <img src={service?.aboutImage} alt=""/>
            
            </div>
          </div>
        
        </div>
        <div style={{position: "relative"}}>
          <div className="color__change" data-color="body" ref={elem => {
            this.body = elem
          }}/>
        </div>
        {
          service?.subserviceCards && (
            <div className="prices" data-color="coffee" ref={elem => {
              this.coffee = elem
            }}>
              <div className="container">
                
                <div className="kindsOfService__cards-container">
                  <Swiper
                    pagination={{clickable: true, el: ".swiper-pagination-custom--subservice"}}
                    loop
                    slidesPerView="1"
                    spaceBetween={50}
                    breakpoints={
                      {
                        
                        490: {
                          slidesPerView: "2"
                        },
                        700: {
                          slidesPerView: "2"
                        },
                        1000: {
                          slidesPerView: "3"
                        },
                      }
                    }
                  >
                    {cards.map((card, i) => (
                      <SwiperSlide key={i}>
                        <ServiceCard
                          to={card.to}
                          title={card.title}
                          image={card.image}
                          price={card.price}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="swiper-pagination-custom--subservice"/>
                
                </div>
              
              </div>
            </div>
          )
        }
        {this.state.priceList.length > 0 &&
        (<div className="prices__list">
            {this.state.priceList.map((item, i) => (
              <PriceRow key={i} item={item}/>
            ))}
          </div>
        )}
      
      
      </>
    )
  }
}


export default ServicePage;
