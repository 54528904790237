import React, {useState, useRef, useEffect} from "react";
import "./BookingDialog.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {services, specialists} from "./BookingDialogDB";
import {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Select from "react-select";
import emailjs from "emailjs-com";

registerLocale('ru', ru);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: '1px solid #514240',
    color: state.isSelected ? 'black' : '#514240',
    backgroundColor: state.isSelected ? "white" : "transparent",
    padding: "5px 10px",
    lineHeight: "40px"
    
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    bgColor: "transparent",
    display: "flex",
  }),
  placeholder: () => ({
    fontSize: 14,
    
    textTransform: "capitalize",
    position: "absolute",
    "@media only screen and (max-width: 1200px)": {},
  }),
  input: () => ({
    lineHeight: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#EFEAE5",
    zIndex: 999
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textTransform: "capitalize",
    fontSize: 14
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    fontSize: "14px",
    textTransform: "capitalize"
  })
};


const BookingDialog = (props) => {
  const [serviceTitle, setServiceTitle] = useState("");
  const [serviceDuration, setServiceDuration] = useState("");
  const [specialistName, setSpecialistName] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  
  const datepicker = useRef(null);
  
  const sendEmail = () => {
      emailjs.send("service_qes0oty", "template_jslm64l", {
        name,
        mail,
        phone,
        serviceTitle: serviceTitle.value,
        serviceDuration: serviceDuration.value,
        specialistName: specialistName.value,
        date: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}   -  ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`
      }, "user_pwtdsxCHiF5fEIbyVsFrs")
        .then(() => {
          window.alert("Форма успешно отправлена");
        }, () => {
          window.alert("Возникла ошибка, попробуйте повторить позднее");
        });
  
  };
  
  useEffect(() => {
    datepicker.current.input.setAttribute("readOnly", true)
  }, []);
  
  
  const handleChangeDate = (dateArr) => {
    const [startDate, endDate] = dateArr;
      setStartDate(startDate);
       setEndDate(endDate);
  
    if(startDate && endDate) {
      datepicker.current.setOpen(false)
  
    }
  };
  const sortServiceDuration = (serviceObj) => {
    return services.filter(service => service.title === serviceObj?.value)[0]?.duration.map(service => ({
      value: service,
      label: service
    }));
  };
  
  const sortSpecialistsByService = (service) => {
    return specialists.filter(specialist => specialist.services.includes(service?.value)).map(specialist => ({
      value: specialist.name,
      label: specialist.name
    }))
  };
  const handleChangeService = (e) => {
    setServiceTitle(e);
    setServiceDuration(null);
    setSpecialistName(null);
  };
  const handleChangeSpecialist = (e) => {
    setSpecialistName(e);
  };
  const handleChangeDuration = (e) => {
    setServiceDuration(e)
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (name && mail && phone && serviceTitle?.value && serviceDuration?.value && specialistName.value && startDate) {
  
      sendEmail();
      setConfirmed(true);
    }
    
  };
  const resetForm = () => {
    setServiceTitle("");
    setSpecialistName("");
    setStartDate(null);
    setEndDate(null);
    setConfirmed(false);
    setServiceDuration("");
    setName("");
    setMail("");
    setPhone("")
    
  };
  const closeForm = () => {
    resetForm();
    props.handleClose();
  };
  return (
    <>
      <div style={{display: props.isOpen ? "block" : "none"}}>
        <div className="backdrop" onClick={closeForm}/>
        <div className="pop-up">
          <button className="pop-up__close" onClick={closeForm}/>
          {!confirmed ? (
            <div className="pop-up__container">
              
              
              <div className="pop-up__content">
                <div className="pop-up__form">
                  <h2 className="pop-up__subtitle">Мы ждем Вас</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="pop-up__form-in">
                      <div className="pop-up__form-row">
                        <div className="pop-up__input pop-up__input-no_effect">
                          <Select
                            onChange={handleChangeService}
                            value={serviceTitle}
                            noOptionsMessage={() => "Нет вариантов"}
                            placeholder="Процедура"
                            options={services.map(service => ({value: service.title, label: service.title}))}
                            styles={customStyles}
                          />
                        </div>
                        <div className="pop-up__input pop-up__input-no_effect">
                          <Select
                            onChange={handleChangeDuration}
                            value={serviceDuration}
                            noOptionsMessage={() => "Нет вариантов"}
                            placeholder="Продолжительность"
                            isDisabled={!serviceTitle}
                            options={sortServiceDuration(serviceTitle)}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="pop-up__form-row">
                        <div className="pop-up__input pop-up__input-no_effect">
                          <DatePicker
                            
                            selectsRange
                            onChange={handleChangeDate}
                            locale="ru"
                            startDate={startDate}
                            ref={datepicker}
                            
                            endDate={endDate}
                            minDate={new Date()}
                            dateFormat="dd.MM.yyyy"
                            shouldCloseOnSelect={false}
                            value={!(startDate && endDate) ?
                               `Выберите желаемый диапазон дат` :
                               `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}   -  ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`
                            }

                          />
                        </div>
                        <div className="pop-up__input pop-up__input-no_effect">
                          <Select
                            onChange={handleChangeSpecialist}
                            value={specialistName}
                            placeholder="Специалист"
                            noOptionsMessage={() => "Нет вариантов"}
                            options={sortSpecialistsByService(serviceTitle)}
                            isDisabled={!serviceTitle}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      
                      <div className="pop-up__input">
                        <input type="text" value={name} onChange={(e) => {
                          setName(e.target.value)
                        }} placeholder="Ваше имя"/>
                      </div>
                      <div className="pop-up__input">
                        <input type="text" value={mail} onChange={(e) => {
                          setMail(e.target.value)
                        }} placeholder="Ваш email"/>
                      </div>
                      <div className="pop-up__input">
                        <input type="text" value={phone} onChange={(e) => {
                          setPhone(e.target.value)
                        }} placeholder="Телефон"/>
                      </div>
                      
                      <div className="pop-up__btn-wrapper">
                        <button className="pop-up__btn" onClick={() => handleSubmit} type="submit">
                          <span>Записаться</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="booking__img">
                
                <h1 className="pop-up__title">Счастье начинается с заботы о себе</h1>
              </div>
            </div>
          ) : (
            <div className="pop-up__container">
              <p className="confirm__message">
                Спасибо, {name}! В ближайшее время с вами свяжется наш оператор
              </p>
            </div>
          )}
        
        </div>
      </div>
    
    </>
  );
};

export default BookingDialog;