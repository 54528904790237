import React from "react";
import "./ServicesCards.css";
import ServiceCard from "./ServiceCard/ServiceCard";

import massage from "../../Assets/content/service-cards/massage.JPG";
import plastic from "../../Assets/content/service-cards/plastic.JPG";
import manicure from "../../Assets/content/service-cards/manicure.jpg";
import pedicure from "../../Assets/content/service-cards/pedicure.jpg";
import correction from "../../Assets/content/service-cards/correction-figure.jpg";
import spa from "../../Assets/content/service-cards/cosmetology.JPG";
import bath from "../../Assets/content/service-cards/cedar-barrel.jpg";
import solarium from "../../Assets/content/service-cards/solarium.jpg";
import {Swiper, SwiperSlide} from "swiper/react";

import SwiperCore, {Pagination} from "swiper";

import "swiper/components/pagination/pagination.scss";
import VizSensor from "../../HOC/VizSensor/VizSensor";


const ServicesCards = (props) => {
  
  SwiperCore.use([Pagination]);
  
  return (
    <>
      <div style={props.style} className="card-service__container">
  
        <Swiper
          pagination={{clickable: true, el: ".swiper-pagination-custom--cards"}}
          loop
          slidesPerView="1"
          spaceBetween={20}
          breakpoints={
            {
              
              650: {
                slidesPerView: "2"
              },
              700: {
                spaceBetween: "50",
              },
              1000: {
                slidesPerView: "3"
              },
            }
          }
  
          >
          <SwiperSlide>
            <ServiceCard
              to="/services/massage"
              title="Массаж"
              image={massage}
              price={900}
              description="16 видов массажа"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceCard
              to="/services/plastic"
              title="Хиропластика лица"
              image={plastic}
              price={2800}
              description="2 Cпа-программы"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceCard
              to="/services/manicure"
              title="Маникюр"
              image={manicure}
              price={1100}
              description="Мужской и женский"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceCard
              to="/services/pedicure"
              title="Педикюр"
              image={pedicure}
              price={1800}
              description="Мужской и женский"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceCard
              to="/services/correction"
              title="Ручная коррекция фигуры"
              image={correction}
              price={2000}
              description="3 Спа-программы"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceCard
              to="/services/cosmetology"
              title="Спа-косметология"
              image={spa}
              price={2100}
              description="7 видов процедур"
            />
          </SwiperSlide>
          {/* <SwiperSlide>
            <ServiceCard
              to="/services/mask"
              title="Маски"
              image="https://images.unsplash.com/photo-1540555700478-4be289fbecef?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8c3BhfGVufDB8fDB8&ixlib=rb-1.2.1&w=1000&q=80"
              price={450}
              description="3 вида масок"
            />
          </SwiperSlide> */}
          <SwiperSlide>
            <ServiceCard
              to="/services/bath"
              title="Кедровая фито-бочка"
              image={bath}
              price={550}
              description="+ фито сбор"
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceCard
              to="/services/solarium"
              title="Солярий"
              image={solarium}
              price={30}
              description="С рекомендациями специалистов"
            />
          </SwiperSlide>
        </Swiper>
  
        <div className="swiper-pagination-custom--cards"/>
      </div>
    </>
  )
};

export default VizSensor(ServicesCards);