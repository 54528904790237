import React, {Component} from 'react';
import VisibilitySensor from "react-visibility-sensor";

export default function VizSensor(HocComponent) {
  return class extends Component {
    state = {
      opacity: 0
    };
    onChange = (bool) => {
      
      
      this.setState({...this.state, opacity: bool ? 1 : 0})
    };
    
    render() {
      return (
        <VisibilitySensor partialVisibility onChange={(bool) => {this.onChange(bool)}}>
          <HocComponent
            style={{opacity: this.state.opacity, transition: `opacity 1s ease-out ${this.props.delay || '0s'}`}}
            {...this.props}
          />
        </VisibilitySensor>
      );
    }
  }
}