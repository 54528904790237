import usual from "../../Assets/content/servicePage/subServices-massage/usual.JPG";
import sport from "../../Assets/content/servicePage/subServices-massage/sport.JPG";
import autor from "../../Assets/content/servicePage/subServices-massage/autor.JPG";
import cellulite from "../../Assets/content/servicePage/subServices-massage/cellulite.JPG";
import neck from "../../Assets/content/servicePage/subServices-massage/neck.JPG";
import back from "../../Assets/content/servicePage/subServices-massage/back.JPG";
import head from "../../Assets/content/servicePage/subServices-massage/head.JPG";
import foots from "../../Assets/content/servicePage/subServices-massage/foots.JPG";
import stomach from "../../Assets/content/servicePage/subServices-massage/stomach.JPG";
import backHealth from "../../Assets/content/servicePage/subServices-massage/back-health.JPG";
import limfo from "../../Assets/content/servicePage/subServices-massage/limfo.jpg";
import swan from "../../Assets/content/servicePage/subServices-massage/swan.JPG";
import relax from "../../Assets/content/servicePage/subServices-massage/relax.JPG";
import sculpture from "../../Assets/content/servicePage/subServices-massage/sculpture.JPG";

//COSMETOLOGY
import scrubber from "../../Assets/content/servicePage/subService__cosmetology/scrubber.JPG";
import withoutInject from "../../Assets/content/servicePage/subService__cosmetology/without-inject.JPG";
import neckReg from "../../Assets/content/servicePage/subService__cosmetology/neck.jpeg";
import combo from "../../Assets/content/servicePage/subService__cosmetology/combo.JPG";
import mezo from "../../Assets/content/servicePage/subService__cosmetology/mezotherapy.JPG";
import carbo from "../../Assets/content/servicePage/subService__cosmetology/carbo.JPG";
import mikrotoki from "../../Assets/content/servicePage/subService__cosmetology/mikrotoki.JPG";
import darson from "../../Assets/content/servicePage/subService__cosmetology/darson.JPG";
import alginat from "../../Assets/content/servicePage/subService__cosmetology/alginat-mask.JPG";
import sooth from "../../Assets/content/servicePage/subService__cosmetology/soothing-mask.jfif";
import nutr from "../../Assets/content/servicePage/subService__cosmetology/nutritious-mask.jfif";


export const pages = {
  massage: {
    1: {
      title: "Общий массаж",
      description: "Общий массаж тела обладает огромной пользой – это цикл SPA-процедур, включающий массаж рук, ног и стоп, спины и шеи, лица и головы, а также области груди и спины. Направлен он на весь организм, и эффект от него следует ожидать комплексный. Во время такого массажа полностью снимается напряжение с тела, восстанавливается кровообращение, улучшаются обменные процессы, появляется бодрость и сила. В обычной жизни очень трудно полностью расслабиться: во время массажа отдыхает каждая клеточка тела, чтобы потом заработать с новыми силами.",
      image: usual,
    },
    2: {
      title: "Спортивный массаж",
      description: "Спортивный массаж представляет собой вид массажа, что на практике применяется для увеличения работоспособности спортсменов и усовершенствования их физических возможностей. Фактически спортивный массаж – еще одно звено в системе спортивной тренировки: его применение способствует быстрому достижению и удержанию спортивной формы. Кроме того, спортивный массаж – не только отличный помощник в борьбе с утомлением, но и своеобразный способ подготовки (на всех стадиях тренировочного процесса) для участия в соревнованиях и непосредственно перед ними.",
      image: sport
    },
    3: {
      title: "Авторский массаж",
      description: "это процедура индивидуального обслуживания пациента. Это вид массажа учитывает физиологическое строение тела каждого клиента, когда уделяется внимание его сильным и слабым зонам. В этом массаже используются различные техники – китайские, японские, испанские, элементы тайского йога-массажа, точечное воздействие. Массаж максимально расслабляет, но и в тоже время очень хорошо разминает шейно-воротниковую зону, область стоп, бедер и ягодиц, улучшается циркуляция крови, повышается тонус мышц, ускоряются обменные процессы в организме.",
        image: autor
    },
    4: {
      title: "Антицеллюлитный",
      description: "Ручной антицеллютный массаж благодаря специальным механическим приемам разогревает ткани, дробит жировые отложения, оказывает выраженный лимфодренажный эффект, устраняет застой лимфы и жидкости, оказывая незначительное мочегонное действие. В результате этого все обменные процессы в организме активизируются, кровь начинает интенсивно циркулировать по сосудам в подкожно-жировой клетчатке, восстанавливается трофика тканей, укрепляются капилляры и активизируется продукция коллагеновых и эластиновых волокон. С помощью массажа в более глубокие слои кожи транспортируются специальные антицеллюлитные масла и лосьоны, что повышает их эффективность в разы.",
      image: cellulite
    },
    5: {
      title: "Шейно-воротниковая зона",
      description: "Массаж позволяет избавиться от излишних солевых отложений, спаек в области лопаток и плеч, а также области верхнего отдела позвоночника; от головных и спинных болей, снять отечности и припухлости. Такой массаж нормализует работу соединительных тканей организма, позволяя предупредить такие заболевания, как остеохондроз, возникновение сколиоза, кривошеи, опускания плеч. Также, на шее человека, в области, расположенной под затылочной частью головы, находятся активные точки, нажатие на которых позволяет определить наличие остеохондроза, а вызванные болевые ощущения показывают стадию данной болезни.",
      image: neck
    },
    6: {
      title: "Массаж спины",
      description: "Позитивный тонус жизни и самочувствие человека во многом зависят от состояния спины, которая занимает большую площадь тела, сосредотачивая в себе множество энергетических точек.Массаж спины снимает усталость, перенапряжение и отечность, улучшает кровообращение, помогает  при проблемах с позвоночником и при травмировании связок и нервов, формирует мышечный корсет.",
      image: back
    },
    7: {
      title: "Массаж головы",
      description: "Кожа головы тесно связана с мозгом, а потому запущенные в ходе воздействия на нее импульсы быстро проникают в кору больших полушарий и лимбические структуры, гармонизируя работу всего тела. Массаж кожи головы активизирует кровообращение и лимфоток, улучшает питание мягких тканей, снимает мышечное напряжение, улучшает настроение и общее состояние пациента. Подобные свойства процедуры находят отражение и во внешнем виде человека: уже через несколько сеансов улучшается цвет лица и состояние волос, уходят мелкие мимические морщины и подтягивается овал лица.",
      image: head
    },
    8: {
      title: "Массаж стоп",
      description: "Массаж ног и стоп – это несравнимое удовольствие, которое не только снимает усталость стоп,  приводит мышцы ног и всего тела в тонус, но и уникальная релаксирующая процедура. При массаже ступней и ног стимулируются не только сами мышцы, на которые приходится воздействие, но благотворное влияние оказывается на весь организм. Происходит это благодаря рефлекторным точкам, которые отвечают за состояние организма в целом.  Например, стимуляция пальцев ног оказывает благотворное воздействие на глаза, уши, зубы и десна, носовые пазухи.",
      image: foots
    },
    9: {
      title: "Массаж живота",
      description: "Массаж живота – эффективное средство профилактики многих заболеваний.Благодаря массажу живота улучшается перистальтика кишечника, что может помочь при атонии кишечника и при длительных постоянных запорах. Массажные движения улучшают секрецию печени и поджелудочной железы, что благотворно сказывается на пищеварении. Кроме этого медицинский, проведенный полным курсом, улучшает состояние кожи и подкожно-жировой клетчатки брюшной стенки. Кроме того, массаж живота – эффективное средство борьбы с целлюлитом и лишними килограммами.",
      image: stomach
    },
    10: {
      title: "Оздоровительный массаж спины",
      description: "Медицинский массаж основан на принципе проработки путей кровообращения и лимфотока. Специалист во время сеанса выполняет движения от области поясницы к лимфоузлам в паху, от зоны груди к подмышкам и т. д. При этом прорабатывается только подкожная мускулатура, благодаря чему устраняются функциональные блоки.",
      image: backHealth
    },
    11: {
      title: "Лимфодренажный массаж",
      description: "Лимфодренажный массаж — специальная методика мануального или аппаратного воздействия на тело, позволяющая оказать влияние на движение лимфы в организме. Это максимально безопасная и результативная процедура, которая воздействует непосредственно на лимфатические узлы и позволяет ускорить передвижение жидкости в организме. Быстрый лимфоотток избавляет от отеков, способствует регенерации тканей и их освобождению от токсинов, а также помогает вывести лишнюю жидкость. Помимо косметического эффекта, лимфодренажный массаж оказывает оздоровительное действие на организм: выведение токсинов, улучшение кровообращения, укрепление иммунитета.",
      image: limfo
    },
    12: {
      title: "Массаж Лебединая шея",
      description: "В шейно-воротниковой зоне чаще всего наблюдается возникновение застойных явлений, отечности, защемление позвонков, отложение солей и нарушение в работе соединительных тканей. В результате возникают такие заболевания, как остеохондроз, сколиоз, кривошея, образуются лопаточные спайки. Избавить Вас от всего этого поможет массаж шеи. Он избавит Вас от излишних солевых отложений, спаек в области лопаток и плеч. Такой массаж позволяет избавиться от головных и спинных болей, снять отечности и припухлости.",
      image: swan
    },
    13: {
      title: "Детский массаж",
      description: "Массаж для детей представляет собой эффективный процесс, который очень полезен почти любому ребенку вне зависимости от возраста. Массажные техники – одно из условий правильного развития опорно-двигательного аппарата. Они дают возможность поддерживать мышечный каркас в тонусе, насыщают организм кислородом на клеточном уровне.Касания, помимо того, что приносят пользу, еще и активизируют деятельность мозга.",
      image: usual
    },
    14: {
      title: "Подростковый массаж",
      description: "Гормональная перестройка, эмоциональные всплески, формирование организма – неотъемлемые атрибуты подростка. Массаж не только укрепляют иммунитет, но и оказывают благотворное психологическое воздействие. Понижает раздражительность, снижает уровень усталости и повышает трудоспособность; предотвращает появление проблем с позвоночником; укрепляет иммунитет; нормализует обмен веществ: решает проблемы с весом, кожей, волосами; нормализует сон, отдых.",
      image: usual
    },
    15: {
      title: "Relax-массаж",
      description: "Расслабляющий релакс массаж – очень приятная процедура, которая позволяет добиться полного восстановления и расслабления. Она наполняет новыми силами и свежими идеями, дарит ощущение полета и радости жизни. Эта процедура будет полезна как в качестве лечебного, так и профилактического метода. Механическое воздействие на периферические нервные окончания стимулируют нервную систему, заставляя организм вырабатывать эндорфины - гормоны счастья.",
      image: relax
    },
    16: {
      title: "Французский скульптурный массаж",
      description: "Польза процедуры заключается в мощном воздействии на разные слои эпидермиса, внутреннем укреплении мышц. Скульптурный массаж – это искусная комбинация нескольких методик. Процедура совмещает элементы остеопатической, точечной, тибетской, классической и лимфодренажной массажной практики. Работа косметолога сравнима со скульптором, который при помощи грамотно поставленных движений «лепит», моделирует контуры лица. Положительные результаты методики подтверждены на практике. Скульптурный массаж обеспечивает длительный, ярко выраженный эффект, сравнимый с хирургической подтяжкой или волшебными уколами «красоты».",
      image: sculpture
    },
  },
  cosmetology: {
    1: {
      title: "Ультра звуковой пиллинг с уходом",
      description: "Ультразвуковой пилинг – процедура очистки кожи c помощью ультразвука, один из самых легких и безболезненных из всех видов пилингов. Удаляет поверхностный слой кожи, разглаживает её поверхность, улучшает кровообращение, благодаря чему происходит увлажнение кожи естественным путем. Также  пилинг очищает кожу от загрязнений, ороговевших клеток, кожного жира, закупорок сальных желез, удаляет акнэ и комедоны. Под ультразвуком мышцы тонизируются и происходит массаж на клеточном уровне, улучшается питание и снабжение кислородом мышц и кожи лица.",
      image: scrubber,
    },
    2: {
      title: "Комбинированная чистка лица с уходом",
      description: "Комбинированная чистка лица представляет собой сочетание механической и ультразвуковой в одной процедуре . Это одна из максимально эффективных и одновременно щадящих салонных процедур, направленных на очищение кожи лица.  Механическое воздействие на кожу производится только на сильно загрязненных участках кожи для их очистки. После чего все воздействия проводят только ультразвуковым скрабером, что делает процедуру более безопасной и комфортной для клиентов.",
      image: combo
    },
    3: {
      title: "Фракционная мезотерапия",
      description: "Фракционная мезотерапия – это подкожное введение полезных для молодости и красоты составов. Процедура введения мезококтейлей делаются специальным аппаратом. И лишь иногда – шприцем с тончайшей иглой. Микроинъекции проводятся точечно – только в проблемные области на небольшую глубину. Поверхность кожи травмируется минимально, а лечебные коктейли помогают разгладить морщины, подтянуть и увлажнить кожу, усилив микроциркуляцию и восстановительные процессы в тканях. Кроме того, эта методика менее болезненна, чем некоторые другие инъекционные процедуры.",
        image: mezo
    },
    4: {
      title: "Карбокситерапия",
      description: "Карбокситерапия – инъекционный метод введения углекислого газа (СО2) под кожу. Углекислый газ- естественный продукт обменных процессов всех клеток и тканей организма. Из-за увеличения количества углекислого газа в тканях происходит активизация микроциркуляции, сосуды расширяются, увеличивается скорость кровотока, увеличивается доставка кислорода клетками крови в эти ткани и органы, улучшается лимфатический дренаж. За счёт выработки коллагена повысится тонус кожи, уменьшится количество жировой ткани, улучшится обмен веществ в клетках и ускорится регенерация.",
      image: carbo
    },
    5: {
      title: "Микротоки для лица",
      description: "Процедура основывается на воздействии тока со сверхнизкой частотностью и амплитудой на организм человека. Ток влияет на лимфатические и кровеносные сосуды, мышцы и активизирует функциональные свойства клеток, которые доставляют полезные вещества, аминокислоты и коллаген внутрь кожного покрова. Активизируется синтез обновленных коллагеновых и эластиновых волокон, повышает тонус мышц, улучшает отток лимфы, приводит в норму обмен веществ, провоцирует микроциркуляцию в тканях. Также микротоки влияют дезинтоксикационно, активизируют работу иммунной системы и сальных желез. ",
      image: mikrotoki
    },
    6: {
      title: "Дарсонвализация",
      description: "Дарсонвализация лица, шеи и век проводится для лечения проблемной кожи и омоложения. Лечебное воздействие на клетки кожи оказывается импульсными высокочастотными токами. Дарсонвализация изменяет физико-химические процессы в тканях, улучшает кровообращение и обменные процессы. Ускоряется регенерация клеток кожи, лицо омолаживается, повышается эластичность и упругость, исчезают мелкие морщинки. Из глубоких слоев кожи выводятся шлаки, сходят оттеки и ликвидируются воспалительные процессы на лице. Также нормализуется работа сальных желез.",
      image: darson
    },
    7: {
      title: "Безинъекционное омоложение лица",
      description: "Безинъекционное омоложение лица – процедура, но не требующая болезненного ввода иглы под кожи. Косметолог подготавливает специальную смесь, содержащую витамины, полезные вещества для поддержания здорового состояния Вашей кожи. Далее смесь наносится на участки, требующие ухода.",
      image: withoutInject
    },
    8: {
      title: "Безинъекционное омоложение шеи",
      description: "Безинъекционное омоложение лица – процедура, но не требующая болезненного ввода иглы под кожи. Косметолог подготавливает специальную смесь, содержащую витамины, полезные вещества для поддержания здорового состояния Вашей кожи. Далее смесь наносится на участки, требующие ухода.",
      image: neckReg
    },
    9: {
      title: "Альгинатная маска",
      description: "Альгинатные маски полностью заполняют даже самые мелкие морщинки и действуют на глубокие слои кожи. Благодаря своей текстуре и способу действия, они подтягивают и корректируют овал лица. Маски не только разглаживает морщины, но и очищают и сужают поры, уменьшают куперозные сеточки, повышают эластичность кожи. Особо следует отметить уникальный антиоксидантные и выводящие (детокс) свойства альгинатов. Они нейтрализуют воздействие солнечного излучения и солей тяжелых металлов, разрушающих коллагеновый слой кожи и стимулирующих процессы потери ее упругости и появления воспалений.",
      image: alginat
    },
    10: {
      title: "Успокаивающая маска",
      description: "Успокаивающие маски для лица обычно делаются для жирной или комбинированной кожи при наличии высыпаний. Чувствительная кожа известна своей восприимчивостью к негативным воздействиям окружающей среды и нуждается в особом уходе. Успокаивающая маска для лица снимет раздражение, ликвидирует шелушение, обогатит кожу витаминами и создаст ощущение свежести. В её состав входят натуральные и максимально мягкие компоненты, без абразивных элементов и веществ с повышенной активностью.",
      image: sooth
    },
    11: {
      title: "Питательная маска",
      description: "Питательная маска – это средство для ухода за дермой, позволяющее достичь косметического или лечебного эффекта. Кожа получает необходимые витамины и микро-, макроэлементы, биологически активные вещества. Запускаются процессы восстановления структуры эпидермиса, обновляется кожа. Вырабатывается  коллагена, исчезают возрастные морщины, кожный покров становится гладким и бархатистым. Повышается уровень синтеза гиалуроновой кислоты, которая отвечает за увлажненность дермы. Нормализуется работа сальных желез, постепенно исчезают расширенные поры и угри. Быстрее заживляются мелкие царапинки. Выравнивается цвет лица. Из эпидермиса выводятся шлаки и токсины. Тормозятся процессы старения кожи лица.",
      image: nutr
    },
    
  },
  
};