const getTitles = serviceArr => serviceArr.map(service => service.title);

const massageServices = [
  {title: "Общий массаж", duration: ["1 час", "1,5 часа"]}, {title: "Массаж спины", duration: ["30 минут"]},{title: "Массаж шейно-воротниковой зоны", duration: ["20 минут"]},{title: "Спортивный массаж", duration: ["1 час", "1,5 часа"]},{title: "Авторский массаж", duration: ["1 час"]},{title: "Лимфодренажный массаж", duration: ["45 минут", "1 час", "1,5 часа"]},{title: "Ручная Коррекция фигуры", duration: ["30 минут", "1 час", "1,5 часа"]},{title: "Медовый массаж", duration: ["???"]},{title: "Антицеллюлитный массаж", duration: ["45 минут", "1 час"]},{title: "Массаж головы", duration: ["30 минут"]},{title: "Массаж стоп", duration: ["30 минут"]},{title: "Массаж живота", duration: ["30 минут"]},{title: "Relax массаж", duration: ["1 час"]},{title: "Детский массаж", duration: ["30 минут"]},{title: "Подростковый массаж", duration: ["1 час"]},{title: "Подростковый массаж: спина", duration: ["???"]},{title: "Кедровая фито-бочка", duration: ["15 минут"]},{title: "Скрабирование", duration: ["???"]},{title: "Обёртывание", duration: ["???"]},
];

const cosmetologyServices = [
  {title: "Хиропластика лица", duration: ["50 минут"]},{title: "Хиропластика для мужчин", duration: ["???"]},{title: "Скульптурный массаж лица", duration: ["???"]},{title: "Буккальный массаж лица", duration: ["???"]},{title: "Щипковый массаж лица", duration: ["???"]},{title: "'Лебединая шея'", duration: ["30 минут"]},{title: "Комбинированная чистка лица", duration: ["1,5 часа"]},{title: "Фракционная мезотерапия", duration: ["1 час"]},{title: "Карбокситерапия", duration: ["80 минут"]},{title: "Микротоки для лица", duration: ["1 час"]},{title: "Дарсонвализация", duration: ["15 минут"]},{title: "Алмазный пилинг", duration: ["???"]},{title: "Ультразвуковой пилинг", duration: ["1,5 часа"]},{title: "Безинъекционное омоложение лица PRX-T33", duration: ["40 минут"]},{title: "Альгинатная маска", duration: ["40 минут"]},{title: "Комплекс 'Абсолютное увлажнение'", duration: ["???"]},{title: "Комплекс 'Дыхание кожи'", duration: ["???"]},{title: "Экспресс уход", duration: ["???"]},{title: "Спа программа для лица", duration: ["???"]},
];

const nailsServices = [
  {title: "Маникюр женский", duration: ["???"]},{title: "Маникюр мужской", duration: ["???"]},{title: "Педикюр женский", duration: ["???"]},{title: "Педикюр мужской", duration: ["???"]},{title: "Долговременное покрытие гель-лаком", duration: ["???"]},{title: "Долговременное покрытие гель-лаком френч", duration: ["???"]},{title: "Покрытие лаком", duration: ["???"]},{title: "Снятие долговременного покрытия гель-лака", duration: ["???"]},{title: "Снятие нашего долговременного покрытия гель-лака", duration: ["???"]},{title: "Ремонт одного ногтя", duration: ["???"]}
];

export const services = massageServices.concat(cosmetologyServices).concat(nailsServices);

export const specialists = [
  {name: "Максим", services: getTitles(massageServices)},
  {name: "Сергей", services: getTitles(massageServices)},
  {name: "Александр", services: getTitles(massageServices)},
  {name: "Ирина", services: getTitles(massageServices)},
  {name: "Лариса", services: getTitles(massageServices)},
  {name: "Олеся", services: getTitles(cosmetologyServices)},
  {name: "Ирина", services: getTitles(cosmetologyServices)},
  {name: "Натали", services: getTitles(nailsServices)},
];
