import React from 'react';
import "./Main.css";
import ServicesCards from "../../Components/ServicesCards/ServicesCards";
import SpecialistsCards from "../../Components/SpecialistsCards/SpecialistsCards";
import Gallery from "../../Components/Main/Gallery/Gallery";
import {Parallax} from "react-parallax";
import About_img from "../../Assets/design/about_promo.png"
import Promos from "../../Components/Main/Promos/Promos";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }
  
  handleScroll() {
    if (this.transparent.offsetTop > window.scrollY && (this.transparent.dataset.color !== this.props.color)) {
      this.props.setColor(this.transparent.dataset.color);
    }
    if (this.elem.offsetTop < window.scrollY && (this.elem.dataset.color !== this.props.color)) {
      this.props.setColor(this.elem.dataset.color);
    }
  }
  
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if(window.innerWidth < 1000) {
      this.props.setColor("transparent")
    }
    console.log(this.props)
  }
  
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  
  render() {
    return (
      <>
        <div className="promo promo-image">
          <div className="container">
            <div className="promo__text">
              <h1 className="promo__title">Спа-центр</h1>
              <h2 className="promo__subtitle">Отдых, положительные эмоции и приятные ощущения.</h2>
              
              <div className="booking-wrapper">
                <span className="booking ms_booking">Онлайн запись</span>
                <a target="_blank" rel="noreferrer" href="https://wa.me/79257900513" className="booking-wrapper__tel">+7 (925) 790-05-13</a>
              </div>
              
            </div>
          </div>
          <div className="color__change" data-color="transparent" ref={elem => {
            this.transparent = elem
          }}/>
        </div>
        
        
        <div className="main">
          <section data-color="body" id="services" ref={elem => {
            this.elem = elem
          }}>
            
            {/*<Promos/>*/}
  
            <div className="container">
              
              
              <div className="title-wrapper">
                <h1 className="main__title">Наши <br/> услуги</h1>
                <div className="main__title-info main__title-info--services">
                  {/* <h3 className="main__subtitle"></h3> */}
                  <p className="main__title-text">С нас начинается
                    забота о себе</p>
                </div>
              </div>
              <ServicesCards/>
            </div>
          </section>
          
          <div className="about__main_link">
            <Parallax renderLayer={percentage => (
              <div className="about__img" style={{backgroundSize: `${130 - percentage * 15}%`}}>
                <p className="about__title">
                  <img src={About_img} />
                </p>
              </div>
            )}/>
          </div>
          
          
          <div className="container">
            <SpecialistsCards/>
            <Gallery/>
          </div>
        
        </div>
      </>
    )
  };
}

export default Main;
