import React, {useState} from "react";
import "./Burger.css"
import {Link} from "react-router-dom";

const Burger = () => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleOpen = () => {
    setIsOpened(!isOpened)
  };
  return (
    <>
      <div className={isOpened ? `navigation` : ""} id="burger">
        
        <div id="burgerBtn" onClick={toggleOpen}/>
        <ul id="nav">
          <li>
            <Link onClick={toggleOpen} to="/">
              Главная
            </Link>
          </li>
          <li>
            <Link onClick={toggleOpen} to="/services">
              Услуги
            </Link>
          </li>
          <li>
            <Link onClick={toggleOpen} to="/prices">
              Цены
            </Link>
          </li>
          <li>
            <Link onClick={toggleOpen} to="/contacts">
              Контакты
            </Link>
          </li>
          <li>
            <Link onClick={toggleOpen} to="/about">
              О нас
            </Link>
          </li>
          <li>
            <a href="https://wa.me/79257900513" className="media__link media__link--whatsapp"/>
            <a target="_blank" href="https://www.instagram.com/sansara_spa_/" className="media__link media__link--insta"/>
            <a href="" className="media__link media__link--tg"></a>
          </li>
        </ul>
      </div>
    </>
  )
};

export default Burger
