import React from "react";
import conceptImage from "../../../../Assets/content/concept.jpg";
import VizSensor from "../../../../HOC/VizSensor/VizSensor";

const Concept = (props) => {
  return (
    <div  className="concept">
      <div className="concept__img">
        <img src={conceptImage} alt=""/>
      </div>
      <div className="concept__text">
        <h1 className="main__title--aboutPage concept__title">Наша философия</h1>
        <p style={props.style} >Мы стремились создать обстановку, которая позволит посетителям расслабиться, погрузиться в состояние
          полного покоя и получить море приятных ощущений. Устройте себе маленький праздник, приходите к нам вместе
          с любимым человеком. Время проведенное в нашем СПА-центре как рукой снимет усталость и стресс, вернут силы
          телу и бодрость душе.</p>
      </div>
    </div>
  )
};

export default  VizSensor(Concept);