import React, {useEffect, useState} from "react";
import {priceList} from "../PricesDB";
import PriceRow from "../../../Components/PriceRow/PriceRow";
import fireApp from "../../../firebase";
import {getFirestore, collection, getDocs} from "firebase/firestore/lite";

const PriceList = () => {
  
  const [priceList, setpriceList] = useState([]);
  
  useEffect(() => {
    fetch('https://strapi-cms.sansara-spa.com/prices').then(r => r.json()).then((r) => {setpriceList(r)})
  }, []);
  
  
  return (
    <>
      <div className="prices__list">
        {
          priceList.map((item, i) => (
            <PriceRow key={i} item={item}/>
          ))
        }
      </div>
    </>
  )
};

export default PriceList;
