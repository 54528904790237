import React from "react";

const SpecialistCard = (props) => {
  return (
    <>
      <div className="card--test">
        <div className="card--test__header">
          <div className="card__img-wrapper">
            <img src={props.image} alt="" className="card__img"/>
          </div>
        </div>
        <div className="card--test__body">
          <div className="card__content">
            <div className="card--test__title-wrapper">
              <h2 className="card__title">{props.name}</h2>
              <h3 className="card__subtitle">{props.specialization}</h3>
            </div>
          </div>
        
        
        </div>
      </div>
    </>
  );
};

export default SpecialistCard