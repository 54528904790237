import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAykH8f6fdbbna8c0bb8KCLcjPssvLK4lQ",
  authDomain: "sansara-example.firebaseapp.com",
  projectId: "sansara-example",
  storageBucket: "sansara-example.appspot.com",
  messagingSenderId: "264085609163",
  appId: "1:264085609163:web:53c931683b0262d9c0194e",
  measurementId: "G-JB36CS9CQ2"
};

let fireApp = initializeApp(firebaseConfig);
export default fireApp;
