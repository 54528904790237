import React from "react";
import "./ServiceCard.css";
import {Link} from "react-router-dom";

const ServiceCard = (props) => {
  return (
    <>
      <Link className="card-service" style={{backgroundImage: `url(${props.image})`}} to={props.to}>
        <div className="card-service__overlay"/>
        <div className="card-service__text">
          <div className="card-service__info">
            <p className="card-service__small">{props.description}</p>
            <span className="card-service__price">от {props.price} ₽</span>
          </div>
      
          <h3 className="card-service__title">{props.title}</h3>
    
        </div>
    
        <div className="card-service__go-corner" href="#">
          <div className="go-arrow" />
        </div>
      </Link>
    </>
  )
};

export default ServiceCard;