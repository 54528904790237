import React, {Component} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Route, Switch} from "react-router";
import Layout from "./Layout/Layout";
import Main from "./Containers/Main/Main";
import "./App.css";
import Contacts from './Containers/Contacts/Contacts';
import About from "./Containers/About/About";
import Services from "./Containers/Services/Services";
import Prices from "./Containers/Prices/Prices"
import ServicePage from "./Containers/ServicePage/ServicePage";
import SubservicePage from "./Containers/SubservicePage/SubservicePage";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import NotFound from "./Containers/NotFound/NotFound";


class App extends Component {
  state = {
    color: "transparent",
    isOpen: false
  };
  
  setColor = (color) => {
    this.setState({...this.state, color})
  };
  
  setIsOpen = (isOpen) => {
    this.setState({...this.state, isOpen});
  };
  
  handleOpen = () => {
    this.setIsOpen(true);
  };
  handleClose = () => {
    this.setIsOpen(false);
  };
  
  render() {
    return (
      <BrowserRouter>
        <Layout handleOpen={this.handleOpen} handleClose={this.handleClose} isOpen={this.state.isOpen} color={this.state.color}>
          <Switch>
            <Route path='/' render={(props) => (
              <>
                <Main {...props} handleOpen={this.handleOpen} color={this.state.color} setColor={this.setColor}/>
                <ScrollToTop setColor={this.setColor} color="transparent"/>
              </>
            )} exact/>
            <Route path='/about' render={(props) => (
              <>
                <About color={this.state.color} setColor={this.setColor}  {...props} />
                <ScrollToTop setColor={this.setColor} color="body"/>
              </>
            )} exact/>
            <Route path='/services' render={(props) => (
              <>
                <Services color={this.state.color} setColor={this.setColor} {...props} />
                <ScrollToTop setColor={this.setColor} color="body"/>
              </>
            )} exact/>
            <Route path='/services/:service' render={(props) => (
              <>
                <ServicePage {...props} setColor={this.setColor}/>
                <ScrollToTop/>
              </>
            )} exact/>
            <Route path='/services/:service/:subservice' render={(props) => (
              <>
                <SubservicePage {...props} />
                <ScrollToTop setColor={this.setColor} color="body"/>
              </>
            )} exact/>
            <Route path='/prices' render={(props) => (
              <>
                <ScrollToTop setColor={this.setColor} color="coffee"/>
                <Prices color={this.state.color} setColor={this.setColor} {...props} />
              </>
            )} exact/>
            
            <Route path='/contacts' render={(props) => (
              <>
                <Contacts setColor={this.setColor} {...props} />
                <ScrollToTop setColor={this.setColor} color="body"/>
              </>
            )} exact/>
            
            <Route path="*" render={(props => (
              <>
                <NotFound {...props}/>
                <ScrollToTop setColor={this.setColor} color="body"/>
              </>
            ))}>
            </Route>
          </Switch>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App;
