import React from "react";
import "./About.css";
import Gallery from "../../Components/Main/Gallery/Gallery";
import SpecialistsCards from "../../Components/SpecialistsCards/SpecialistsCards";
import ServicesCards from "../../Components/ServicesCards/ServicesCards"
import Concept from "./Parts/Conceps/Concept";
import Description from "../../Components/Main/About/About"
class About extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <>
        <div className="container" data-color="body" />
        <section id="about">
          <Description/>
        </section>
        <div className="container">
          <Gallery/>
        
        </div>
        
        <Concept delay="0.4s" />
        
        <div className="container">
          <SpecialistsCards/>
          <h1 className="main__title--aboutPage">Мы предлагаем вашему вниманию:</h1>
          <ServicesCards/>
        </div>
      </>
    )
  }
}


export default About;