import React from "react";
import VizSensor from "../../../../HOC/VizSensor/VizSensor";

const ServiceTitle = (props) => {
  return (
    <>
      <div style={props.style} className="about-service__title-wrapper">
        <h1 className="about-service__title">
          {props.title}
        </h1>
      </div>
    </>
  )
};

export default VizSensor(ServiceTitle)