import React from "react";
import "./Promos.css"


import SwiperCore, {Autoplay, A11y, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

SwiperCore.use([Autoplay, A11y]);

const Promos = props => {
  return (
    <>
      <h1 className="promos__title">Специальные предложения</h1>
      <Swiper
        className="promo_slider"
        followFinger={false}
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 5000
        }}
      >
        <SwiperSlide>
          <div className="promotion-container">
            
            <div className="promotion">
              <div className="promotion__img">
                <img
                  src="https://images.unsplash.com/photo-1451443700141-5ddb6d85a8fc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
                  alt=""/>
              </div>
              <div className="promotion__text">
                <div className="promotion__title">
                  <h1>Акция!</h1>
                  <h2>Новая процедура: <br/> <span className="promotion-subtitle__marker">"Обновление и преображение кожи"</span>
                  </h2>
                </div>
                <div className="promotion__info">
                  <p>Продолжительность: <span className="promotion__duration"> 1 час 10 мин </span></p>
                  <ul className="promotion__ul">
                    <li className="ptomotion__li">Кедровая бочка с целебными травами</li>
                    <li className="ptomotion__li">Скрабирование с элементами массажа</li>
                    <li className="ptomotion__li">Увлажняющий лосьон</li>
                    <li className="ptomotion__li">Зеленый чай "Молочный улун"</li>
                  </ul>
                  <div className="promotion__prices">
                    <h1 className="price price--initial">2300 ₽</h1>
                    <h1 className="price price--current">1900 ₽</h1>
                  </div>
                  <div className="promotion__additional">
                    Успейте записаться до 20.05.2021
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
          <div className="promotion-container">
            <div className="promotion">
              <div className="promotion__img">
                <img src="https://spb.letbefit.ru/img/image007_222.jpg" alt=""/>
              </div>
              <div className="promotion__text">
                <div className="promotion__title">
                  <h1>Специальное предложение</h1>
                  <h2>Скидка <span>20%</span> на букальный&nbsp;массаж&nbsp;лица</h2>
                </div>
                <div className="promotion__info">
                  <p>Эффект омоложения заметен после&nbsp;первого&nbsp;сеанса. <br/> Успейте записаться со скидкой
                    к&nbsp;мастеру&nbsp;<i>Ирине</i></p>
                  
                  
                  <div className="promotion__additional">
                    Скидка действует до 20.05.2021
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
};

export default Promos;