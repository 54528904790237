import React, {useEffect} from 'react';
import "./Contacts.css";
import {YMaps, Map, Placemark, ZoomControl} from "react-yandex-maps";

import rectangle from "../../Assets/content/contacts/rectangle.JPG";
import square from "../../Assets/content/contacts/square.JPG"
import {Parallax} from "react-parallax";
import PinImg from "../../Assets/design/map-pin.png"



const Contacts = (props) => {
  
  const mapData = {
    center: [55.88985262513812, 37.38882427629623],
    zoom: 16,
  };
  
  const coordinates = [
    [55.88985262513812, 37.38882427629623],
  ];
  
  useEffect(() => {
    if(window.innerWidth < 1000) {
      props.setColor("body")
    }
  }, [])
  return (
    <>
      <div className="contacts-promo">
        
        <div className="container">
          
          
          <h1 className="contacts-promo__title">Как нас найти?</h1>
          <div className="contacts-promo__img contacts-promo__img--square contacts-promo__img--unset">
            <Parallax className="overflow-unset xy-full" renderLayer={(perc) => (
              <img style={{transform: `translateY(${perc * 70 - 50}px)`}} src={square} alt=""/>
            )}/>
          </div>
          
          <div className="contacts-promo__img contacts-promo__img--rectangle">
            <Parallax className="overflow-unset xy-full" renderLayer={(perc) => (
              <img style={{transform: `translateY(-${perc * 50}px)`}} src={rectangle} alt=""/>
            
            )}/>
          
          </div>
        
        </div>
      
      
      </div>
      <div id="map" className="map__wrapper">
        <YMaps>
          <Map width="100%" height="350px" defaultState={mapData}>
            <ZoomControl />
            {coordinates.map((coordinate, i) => <Placemark
              key={i}
              modules={["geoObject.addon.hint", "geoObject.addon.balloon"]}
              properties={{
                hintContent: "Нажмите, чтобы получить больше информации",
  
                balloonContent: "<p>ул. Воротынская, дом 18, ТЦ «Корабль», цокольный этаж</p>",
                balloonContentHeader: "Дополнительно:"
              }}
              options={{
                fillColor: '#00FF00',
                strokeColor: '#0000FF',
                iconColor: "red",
                iconLayout: "default#image",
                iconImageSize: [45, 60],
                iconImageOffset: [-20, -60],
                hideIconOnBalloonOpen: false,
                iconImageHref: PinImg,
                opacity: 0.5,
                strokeWidth: 1,
                strokeStyle: 'solid',
              }}
              geometry={coordinate}/>)}
          </Map>
        </YMaps>
      </div>
    </>
  );
};


export default Contacts;