import massagePromo from "../../Assets/content/servicePage/massage/top.JPG";
import massageAbout from "../../Assets/content/servicePage/massage/bottom.JPG";

import cosmetologyPromo from "../../Assets/content/servicePage/cosmetology/top.jfif";
import cosmetologyAbout from "../../Assets/content/servicePage/cosmetology/bottom.JPG";

import correctionPromo from "../../Assets/content/servicePage/correction/top.JPG";
import correctionAbout from "../../Assets/content/servicePage/correction/bottom.JPG";

import plasticPromo from "../../Assets/content/servicePage/chyroplastic/top.PNG";
import plasticAbout from "../../Assets/content/servicePage/chyroplastic/bottom.JPG";

import manicurePromo from "../../Assets/content/servicePage/manicure/top.JPG";
import manicureAbout from "../../Assets/content/servicePage/manicure/bottom.jpg";

import pedicurePromo from "../../Assets/content/servicePage/pedicure/top.JPG";
import pedicureAbout from "../../Assets/content/servicePage/pedicure/bottom.JPG";

import solariumPromo from "../../Assets/content/servicePage/solarium/top.HEIC";
import solariumAbout from "../../Assets/content/servicePage/solarium/bottom.JPG";

import bathPromo from "../../Assets/content/servicePage/cedar/top.jpg";
import bathAbout from "../../Assets/content/servicePage/cedar/bottom.JPG";

//SUB MASSAGE

import usual from "../../Assets/content/servicePage/subServices-massage/usual.JPG";
import sport from "../../Assets/content/servicePage/subServices-massage/sport.JPG";
import autor from "../../Assets/content/servicePage/subServices-massage/autor.JPG";
import cellulite from "../../Assets/content/servicePage/subServices-massage/cellulite.JPG";
import neck from "../../Assets/content/servicePage/subServices-massage/neck.JPG";
import back from "../../Assets/content/servicePage/subServices-massage/back.JPG";
import head from "../../Assets/content/servicePage/subServices-massage/head.JPG";
import foots from "../../Assets/content/servicePage/subServices-massage/foots.JPG";
import stomach from "../../Assets/content/servicePage/subServices-massage/stomach.JPG";
import backHealth from "../../Assets/content/servicePage/subServices-massage/back-health.JPG";
import limfo from "../../Assets/content/servicePage/subServices-massage/limfo.jpg";
import swan from "../../Assets/content/servicePage/subServices-massage/swan.JPG";
import relax from "../../Assets/content/servicePage/subServices-massage/relax.JPG";
import sculpture from "../../Assets/content/servicePage/subServices-massage/sculpture.JPG";


//SUB COSMETOLOGY

import scrubber from "../../Assets/content/servicePage/subService__cosmetology/scrubber.JPG";
import withoutInject from "../../Assets/content/servicePage/subService__cosmetology/without-inject.JPG";
import neckReg from "../../Assets/content/servicePage/subService__cosmetology/neck.jpeg";
import combo from "../../Assets/content/servicePage/subService__cosmetology/combo.JPG";
import mezo from "../../Assets/content/servicePage/subService__cosmetology/mezotherapy.JPG";
import carbo from "../../Assets/content/servicePage/subService__cosmetology/carbo.JPG";
import mikrotoki from "../../Assets/content/servicePage/subService__cosmetology/mikrotoki.JPG";
import darson from "../../Assets/content/servicePage/subService__cosmetology/darson.JPG";
import alginat from "../../Assets/content/servicePage/subService__cosmetology/alginat-mask.JPG";
import sooth from "../../Assets/content/servicePage/subService__cosmetology/soothing-mask.jfif";
import nutr from "../../Assets/content/servicePage/subService__cosmetology/nutritious-mask.jfif";


export const pages = {
  massage: {
    promoTitle: "Массаж",
    promoText: "То, что нужно вашему телу",
    promoImage: massagePromo,
    serviceTitle: "Позвольте себе кусочек отпуска, Вашему телу нужна перезагрузка.",
    aboutSubitile: "Универсальное средство",
    aboutDescription: "Наше время сверхскоростей и бесконечной статики (сидение за компьютером, за рулем автомобиля и.т.д) массаж уже давно стал средством первой необходимости. Хороший массаж способен облегчить состояние при нарушениях опорно-двигательного аппарата, болезнях органов дыхания и пищеварения, сердечно-сосудистой и нервной системы, а также при высоких физической и умственной нагрузках. Приходите и убедитесь в этом сами:",
    aboutImage: massageAbout,
    subserviceCards: [
      {
        to: "/services/massage/1",
        title: "Общий",
        price: 2800,
        image: usual,
      },
      {
        to: "/services/massage/2",
        title: "Спортивный",
        price: 3200,
        image: sport,
      },
      {
        to: "/services/massage/3",
        title: "Авторский",
        price: 3500,
        image: autor,
      },
      {
        to: "/services/massage/4",
        title: "Антицеллюлитный",
        price: 3500,
        image: cellulite,
      },
      {
        to: "/services/massage/5",
        title: "Шейно-воротниковая зона",
        price: 900,
        image: neck,
      }, {
        to: "/services/massage/6",
        title: "Массаж спины",
        price: 1500,
        image: back,
      },
      {
        to: "/services/massage/7",
        title: "Массаж головы",
        price: 1300,
        image: head,
      },
      {
        to: "/services/massage/8",
        title: "Массаж стоп",
        price: 1200,
        image: foots,
      },
      {
        to: "/services/massage/9",
        title: "Массаж живота",
        price: 1500,
        image: stomach,
      },
      {
        to: "/services/massage/10",
        title: "Оздоровительный",
        price: 2500,
        image: backHealth,
      },
      {
        to: "/services/massage/11",
        title: "Лимфодренажный",
        price: 2200,
        image: limfo,
      },
      {
        to: "/services/massage/12",
        title: "Лебединая шея",
        price: 1500,
        image: swan,
      },
      {
        to: "/services/massage/13",
        title: "Детский",
        price: 1500,
        image: usual,
      },
      {
        to: "/services/massage/14",
        title: "Подростковый",
        price: 2500,
        image: usual,
      },
      {
        to: "/services/massage/15",
        title: "Relax-массаж",
        price: 3500,
        image: relax,
      },
      {
        to: "/services/massage/16",
        title: "Французский-скульптурный",
        price: 3000,
        image: sculpture,
      },
     
    ],
    // priceList: [
    //   {
    //     title: "Общий массаж",
    //     description: "",
    //     variants: [{title: "1 час", price: "2800"}, {title: "1.5 часа", price: "3500"}]
    //   },
    //   {
    //     title: "Спортивный массаж",
    //     description: "",
    //     variants: [{title: "1 час", price: "3200"}, {title: "1.5 часа", price: "4000"}]
    //   },
    //   {
    //     title: "Авторский",
    //     description: "",
    //     variants: [{title: "1 час", price: "3500"},]
    //   },
    //   {
    //     title: "Антицеллюлитный",
    //     description: "",
    //     variants: [{title: "45 мин", price: "3500"}, {title: "1 час", price: "4000"}]
    //   },
    //   {
    //     title: "Шейно-воротниковая зона",
    //     description: "",
    //     variants: [{title: "20 мин", price: "900"}, ]
    //   },
    //   {
    //     title: "Массаж спины",
    //     description: "",
    //     variants: [{title: "30 мин", price: "1500"},]
    //   },
    //   {
    //     title: "Массаж головы",
    //     description: "",
    //     variants: [{title: "30 мин", price: "1300"},]
    //   },
    //   {
    //     title: "Массаж стоп",
    //     description: "",
    //     variants: [{title: "30 мин", price: "1200"},]
    //   },
    //   {
    //     title: "Массаж живота",
    //     description: "",
    //     variants: [{title: "30 мин", price: "1500"}, ]
    //   },
    //   {
    //     title: "Оздоровительный массаж спины",
    //     description: "",
    //     variants: [{title: "1 час", price: "2500"}, ]
    //   },
    //   {
    //     title: "Лимфодренажный массаж",
    //     description: "",
    //     variants: [{title: "45 мин", price: "2200"}, {title: "1 час", price: "2900"}, {title: "1.5 час", price: "3500"}, ]
    //   },
    //   {
    //     title: "Массаж Лебединная шея",
    //     description: "",
    //     variants: [{title: "30 мин", price: "1500"}, ]
    //   },
    //   {
    //     title: "Детский массаж",
    //     description: "",
    //     variants: [{price: "1500"}, ]
    //   },
    //
    //   {
    //     title: "Подростковый массаж",
    //     description: "",
    //     variants: [{ price: "2500"}, ]
    //   },
    //   {
    //     title: "Relax-массаж",
    //     description: "",
    //     variants: [{ price: "3500"}, ]
    //   },
    //   {
    //     title: "Французский скульптурный массаж",
    //     description: "",
    //     variants: [{price: "3000"}, ]
    //   },
    // ]
  },
  cosmetology: {
    promoTitle: "Спа-косметология",
    promoText: "Индивидуальный подход к каждому",
    promoImage: cosmetologyPromo,
    serviceTitle: "Для того, чтобы всегда выглядеть свежо и привлекательно, достаточно регулярно проводить правильно подобранные косметологические процедуры.",
    aboutSubitile: "Молодость и красота",
    aboutDescription: "Наш мастер с лёгкостью подберет для Вас индивидуальную программу, отвечающую всем запросам вашей кожи. Благодаря нам, Вы получите молодую, гладкую, и свежую кожу, что непременно преобразит ваш внешний вид и улучшит настроение. Пройдя такой курс, можно на некоторое время забыть о стрессе и усталости, укрепить иммунитет, что особенно важно с наступлением холодов",
    aboutImage: cosmetologyAbout,
    subserviceCards: [
      {
        to: "/services/cosmetology/1",
        title: "Ультразвуковой пилинг",
        price: 2700,
        image: scrubber
      },
      {
        to: "/services/cosmetology/2",
        title: "Комбинированная чистка лица",
        price: 3200,
        image: combo,
      },
      {
        to: "/services/cosmetology/3",
        title: "Фракционная мезотерапия",
        price: 3000,
        image: mezo,
      },
      {
        to: "/services/cosmetology/4",
        title: "Карбокситерапия",
        price: 3000,
        image: carbo,
      },
      {
        to: "/services/cosmetology/5",
        title: "Микротоки для лица",
        price: 2100,
        image: mikrotoki,
      },
      {
        to: "/services/cosmetology/6",
        title: "Дарсонвализация",
        price: 500,
        image: darson,
      },
      {
        to: "/services/cosmetology/7",
        title: "Безинъекционное омоложение лица",
        price: 5000,
        image: withoutInject,
      },
      {
        to: "/services/cosmetology/8",
        title: "Безинъекционное омоложение шеи",
        price: 3700,
        image: neckReg,
      },
      {
        to: "/services/cosmetology/9",
        title: "Альгинатная маска",
        price: 700,
        image: alginat,
      },
      {
        to: "/services/cosmetology/10",
        title: "Успокаивающая маска",
        price: 450,
        image: sooth,
      },
      {
        to: "/services/cosmetology/11",
        title: "Питательная маска",
        price: 600,
        image: nutr,
      },
    ],
    // priceList: [
    //   {
    //     title: "Ультразвуковой пилинг",
    //     description: "",
    //     variants: [{title: "1.5 часа", price: "2700"}]
    //   },
    //
    //   {
    //     title: "Комбинированная чистка лица",
    //     description: "ультразвуковая чистка + механическая чистка + маска",
    //     variants: [{title: "1.5 часа", price: "3200"}]
    //   },
    //   {
    //     title: "Фракционная мезотерапия",
    //     description: "",
    //     variants: [{title: "1 час", price: "3000"}]
    //   },
    //   {
    //     title: "Карбокситерапия",
    //     description: "",
    //     variants: [{title: "80 минут", price: "3000"}]
    //   },
    //   {
    //     title: "Микротоки для лица",
    //     description: "",
    //     variants: [{title: "1 час", price: "2100"}]
    //   },
    //   {
    //     title: "Дарсонвализация",
    //     description: "",
    //     variants: [{title: "15 минут", price: "500"}]
    //   },
    //   {
    //     title: "Безинъекционное омоложение лица",
    //     description: "PRX-T 33",
    //     variants: [{title: "40 минут", price: "5000"}]
    //   },
    //   {
    //     title: "Безинъекционное омоложение шеи",
    //     description: "PRX-T 33",
    //     variants: [{title: "30 минут", price: "3700"}]
    //   },
    //   {
    //     title: "Альгинатная маска",
    //     description: "",
    //     variants: [{title: "40 минут", price: "700"}]
    //   },
    //   {
    //     title: "Успокаивающая маска",
    //     description: "",
    //     variants: [{title: "30 минут", price: "450"}]
    //   },
    //   {
    //     title: "Питательная маска",
    //     description: "с коллагеном",
    //     variants: [{title: "30 минут", price: "600"}]
    //   },
    //
    //   ]
  },
  correction: {
    promoTitle: "Ручная коррекция фигуры",
    promoText: "",
    promoImage: correctionPromo,
    serviceTitle: "«Каждая привыкла бороться за свою красоту…»",
    aboutSubitile: "Что такое ручная коррекция фигуры?",
    aboutDescription: "Это единственный нехирургический метод исправления линий, пропорций и форм, как конституционных, так и наследственных недостатков фигуры. Организм – единое целое, все мышцы в теле, абсолютно все, связаны между собой в единый мышечный костюм. Одна цепляется за другую. Именно поэтому для молодости и красоты лица нам необходимы ровные расслабленные мышцы спины и шеи, которые ничего не тянут, не зажимают и не создают на лице ненужных деформаций.",
    aboutImage: correctionAbout,
  },
  plastic: {
    promoTitle: "Хиропластический массаж лица",
    promoText: "",
    promoImage: plasticPromo,
    serviceTitle: "Kомплексное омоложение без скальпеля и побочных действий",
    aboutSubitile: "Ботокс или массаж?",
    aboutDescription: "Сейчас все чаще встает вопрос именно так. Одной из самых эффективных и\n" +
      "              прогрессивных массажных техник на сегодняшний день является хиропластический массаж. Как отдельная\n" +
      "              методика он был разработан испанским профессором, специалистом по мануальной терапии. Сейчас эта процедура\n" +
      "              набирает все большую популярность и собирает множество положительных отзывов как у женщин, так и у мужчин,\n" +
      "              благодаря прекрасным результатам.",
      aboutImage: plasticAbout,
  },
  manicure: {
    promoTitle: "Маникюр",
    promoText: "То, что действительно важно",
    promoImage: manicurePromo,
    serviceTitle: "Ухоженные руки, красивые и здоровые ногти – одна из важных составляющих внешнего вида",
    aboutSubitile: "Записывайтесь на ноготочки",
    aboutDescription: "В нашем распоряжении профессиональные инструменты, современные устройства для аппаратного маникюра, а также средства для ухода за ногтями и кожей рук от лучших зарубежных производителей. И, конечно же, мы не забываем о вашей безопасности – используем только стерильные инструменты и приборы. Уютная атмосфера, творческий подход к работе сделают незабываемым любой визит в наш Спа центр.",
    aboutImage: manicureAbout,
  },
  pedicure: {
    promoTitle: "Педикюр",
    promoText: "С заботой о ваших стопах",
    promoImage: pedicurePromo,
    serviceTitle: "Связь между внутренними органами и человеческой стопой обнаружили еще в древности. Регулярный массаж стоп помогал людям укрепить иммунитет, улучшить кровообращение, продлить долголетие и молодость организма.",
    aboutSubitile: "Здоровье начинается с заботы",
    aboutDescription: "Ухоженные и красивые ноги – это один из признаков здоровья. Многие думают, что педикюр это только придание формы ногтям и их окрашивание. Но это еще и забота о коже ног, что очень важно не только для женщин, но и для мужчин, чьи ноги нуждаются в уходе, пожалуй даже больше, чем женские, поскольку большинство мужчин об этом уходе просто забывают. Педикюр, если делать его регулярно, предотвратит многие проблемы, а также позволит улучшить кровообращение. Нужно сказать, что педикюр необходим всем людям старше тридцати лет, поскольку старение организма всегда начинается с ног",
    aboutImage: pedicureAbout,
  },
  solarium: {
    promoTitle: "Солярий",
    promoText: "То, что Вы заслужили",
    promoImage: solariumPromo,
    serviceTitle: "Для более ровного и качественного загара мы подготовим Ваше тело. В услугу входит скрабирование, пиллинг, легкий лимфодренажный массаж, нанесение бронзатора",
    aboutSubitile: "Почему солярий?",
    aboutDescription: "В отличие от пляжа, в солярии ультрафиолет куда более щадящий и нежный, благодаря современным лампам и фильтрам. Мы регулярно заменяем лампы, контролируем их ресурс, проводим диагностику.",
    aboutImage: solariumAbout,
  },
  bath: {
    promoTitle: "Кедровая фитобочка",
    promoText: "Польза без перегрузки",
    promoImage: bathPromo,
    serviceTitle: "Уникальный природный метод оздоровления благодаря целебному воздействию эфирных масел кедра и, одновременно, пара на организм.",
    aboutSubitile: "Почему стоит пoпробовать?",
    aboutDescription: "Фитобочка из сибирского кедра действует как минисауна, только более эффективно, поскольку человек в ней «парит» только тело, в то время как его голова находится снаружи. Это позволяет легко дышать, нет повышенной нагрузки на сердце. Тело хорошо разогревается, улучшается кровоток, нормализуются обменные процессы. Замедляется накопление и отложение жиров. Высокая температура, которая поддерживается внутри фитобочки, а также насыщенный лечебными травами пар очищают организма от токсинов шлаков за счет расширения кровеносных сосудов и увеличиения потоотделения. Регулярное использование фитобочки благоприятно воздействует на имунную систему и оздоровляет организм. Вы избавитесь от спазмов и мышечных болей, ваш обмен веществ придет в норму. Улучшится состояние сердечно-сосудистой и нервной систем организма.",
    aboutImage: bathAbout,
  },
};
