import React from "react";
import "./Services.css";
import ServicesCards from "../../Components/ServicesCards/ServicesCards"
import Marquee from "react-fast-marquee/dist";


class Services extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  
  render() {
    return (
      <>
        
        <div className="container" data-color="body">
          <Marquee marginRigth="100px" pauseOnClick={true} className="promo-marquee" speed={150} gradient={false}>
              <span>
                Здоровье нашего тела в наших с вами руках. ⠀ ⠀ ⠀ ⠀ ⠀ ⠀ ⠀ ⠀ ⠀
              </span>
          </Marquee>
          
          <div className="title-wrapper title-wrapper--services">
            <h1 className="main__title">Наши услуги</h1>
            <p className="main__p">
              Устройте себе маленький праздник, позвольте себе расслабиться, погрузиться в состояние полного покоя и
              получить море приятных ощущений.
            </p>
          </div>
          
          <ServicesCards/>
        
        
        </div>
      </>
    );
  }
}


export default Services;