import React from "react";
import "./NotFound.css";

const NotFound = (props) => {
  return (
    <>
      <div className="not-found__container">
        <div className="not-found__wrapper">
          <h1>
            404
          </h1>
          <p>Такой страницы не существует</p>
        </div>
      </div>
    
    </>
  )
};

export default NotFound