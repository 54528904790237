import React from "react";
import "./About.css";
import {Parallax} from 'react-parallax';

const About = () => {
  return (
    <>
      <div className="about">
        
        <div className="container">
          
          <div className="about__info">
            <h2 className="about__subtitle">Почему «Сансара»?</h2>
            <div className="about__text-wrapper">
              <Parallax className="parallax-about" renderLayer={percentage => (
                <p style={{transform: `translateY(${percentage * 170 - 150}px)`, opacity: `${percentage}`}}
                   className="about__text">
                  В переводе с санскрита это слово означает «круговорот, протекание, перерождение». Но если не вдаваться
                  в глубины восточных религий, то все равно очевидно, что в нашем теле все процессы так же цикличны.
                  <br/>
                  Одно проистекает из другого, все взаимосвязано и имеет свои причины и свои последствия.
                </p>
              )}/>
              <Parallax className="parallax-about-min" renderLayer={percentage => (
                <p style={{transform: `translateY(${percentage * 120 - 150}px)`, opacity: `${percentage}`}}
                   className="about__text">
                  В переводе с санскрита это слово означает «круговорот, протекание, перерождение». Но если не вдаваться
                  в глубины восточных религий, то все равно очевидно, что в нашем теле все процессы так же цикличны.
                  <br/>
                  Одно проистекает из другого, все взаимосвязано и имеет свои причины и свои последствия.
                </p>
              )}/>
            </div>
            <div className="about__text-wrapper--mobile">
              <p className="about__text">
                  В переводе с санскрита это слово означает «круговорот, протекание, перерождение». Но если не вдаваться
                  в глубины восточных религий, то все равно очевидно, что в нашем теле все процессы так же цикличны.
                  <br/>
                  Одно проистекает из другого, все взаимосвязано и имеет свои причины и свои последствия.
              </p>
            </div>
          </div>
          <div className="about__info about__info--reverse">
            <h2 className="about__subtitle">Почему это важно?</h2>
            <div className="about__text-wrapper">
              {/*<h3 class="about__text--bold">Почему «Сансара»?</h3>  */}
              <Parallax className="parallax-about" renderLayer={percentage => (
                <p style={{transform: `translateY(${percentage * 170 - 150}px)`, opacity: `${percentage}`}}
                   className="about__text">
                  Современная жизнь в мегаполисе отнимает много сил и времени, мы забываем о том, что наши тело и душа
                  требуют отдыха, гармонии, красоты и правильного ухода. Стрессовые ситуации действуют угнетающе и
                  ускоряют старение организма.
                  <br/>
                  Именно поэтому важно дать себе возможность отдохнуть от повседневных забот, расслабиться и насладиться
                  покоем в приятной обстановке.
                </p>
              )}>
              </Parallax>
  
              <Parallax className="parallax-about-min" renderLayer={percentage => (
                <p style={{transform: `translateY(${percentage * 120 - 150}px)`, opacity: `${percentage}`}}
                   className="about__text">
                  Современная жизнь в мегаполисе отнимает много сил и времени, мы забываем о том, что наши тело и душа
                  требуют отдыха, гармонии, красоты и правильного ухода. Стрессовые ситуации действуют угнетающе и
                  ускоряют старение организма.
                  <br/>
                  Именно поэтому важно дать себе возможность отдохнуть от повседневных забот, расслабиться и насладиться
                  покоем в приятной обстановке.
                </p>
              )}>
              </Parallax>
              
            
            </div>
            <div className="about__text-wrapper--mobile">
              <p className="about__text">
              Современная жизнь в мегаполисе отнимает много сил и времени, мы забываем о том, что наши тело и душа
                  требуют отдыха, гармонии, красоты и правильного ухода. Стрессовые ситуации действуют угнетающе и
                  ускоряют старение организма.
                  <br/>
                  Именно поэтому важно дать себе возможность отдохнуть от повседневных забот, расслабиться и насладиться
                  покоем в приятной обстановке.
              </p>
            </div>
          
          </div>
        </div>
        <div className="about__info about__info--center">
          <h2 className="about__subtitle about__subtitle--no-margin">Почему мы?</h2>
          <div className="about__text-wrapper">
            {/*<h3 class="about__text--bold">Почему «Сансара»?</h3> */}
            <p className="about__text">
              Мы стремились создать обстановку, которая позволит посетителям расслабиться, погрузиться в состояние
              полного покоя и получить море приятных ощущений.
              <br/>
              Устройте себе маленький праздник, приходите к нам вместе с любимым человеком. Время проведенное в
              нашем СПА-центре как рукой снимет усталость и стресс, вернут силы телу и бодрость душе.
            
            </p>
          </div>
          <div className="about__text-wrapper--mobile">
              <p className="about__text">
              Мы стремились создать обстановку, которая позволит посетителям расслабиться, погрузиться в состояние
              полного покоя и получить море приятных ощущений.
              <br/>
              Устройте себе маленький праздник, приходите к нам вместе с любимым человеком. Время проведенное в
              нашем СПА-центре как рукой снимет усталость и стресс, вернут силы телу и бодрость душе.
              </p>
            </div>
        </div>
      </div>
    
    </>
  );
};

export default About;