import React from "react";
import VizSensor from "../../HOC/VizSensor/VizSensor";

const PriceRow = (props) => {
  const {item, style} = props;
  return (
    <>
      <div style={style} className="prices__row">
        <div className="prices__serviceName">
          <h4>{item.title}</h4>
          <p>{item.description}</p>
        </div>
        <div className="prices__price prices__price--grid">
          {
            item.variants.map((variant, i) => (
              <div key={i}>
                <h4>{variant.price}</h4>
                <p>{variant.title}</p>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
};

export default VizSensor(PriceRow);