import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if(props?.setColor && props?.color && window.innerWidth >= 1000) {
      props.setColor(props.color);
    }
  }, [pathname]);
  
  return null;
}